'use client'
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import useWindowSize from "@/app/hooks/useWindowSize";
import {useRouter} from "next/navigation";

const HomeCollagenImpression = ({data}) => {

    const {isMobile} = useWindowSize()
    const router = useRouter();

    const handleImageClick = (destinationPath) => {
        router.push('/' + destinationPath);
    };

    return (<div className={'md:bg-cruxBeige lg:pt-[100px] xxl:pt-[400px] 2xl:pt-[300px] 3xl:pt-[300px]'}>
        <MainContainerWrapper>
        <div className={'flex flex-col md:flex-row justify-between mx-auto items-center text-center w-full 3xl:px-[50px]'}>
                {data?.collagen?.home_collagen_impression_repeater.map((item, index) => (
                    <div key={index}>
                        <h2 className={'text-cruxGreen text-[40px] xl:text-[56px] 2xl:text-[64px] font-[700] leading-[36px]'}>{item?.collagen_percentage}</h2>
                        <div className={'border-b border-cruxBrown w-[150px] xl:text-[200px] 2xl:w-[214px] my-[9px] mx-auto'}></div>
                        <p className={'text-[14px] md:text-[20px] text-cruxGray font-[300] leading-[28px] mb-[45px] md:mb-[0px]'}>{item?.collagen_percentage_label}</p>
                    </div>
                ))}
            </div>
            <div className={'flex flex-col justify-center items-center md:mt-[100px] bg-cruxGreen w-full rounded-[30px] py-[60px]'}>
                <div className={'border-b border-[#F7F4ED] w-[150px] md:w-[600px] mx-auto items-center mb-[42px] mt-[43px] md:mt-[0px]'}></div>
                <div className={'text-[#F7F4ED] text-[16px] lg:text-[18px] xxl:text-[20px] font-[400] md:font-[300] leading-[20px] md:leading-[24px] tracking-[1.6px] md:tracking-[2px] text-center md:text-normal px-[24px] md:px-[0px] max-w-[660px] mb-[23px]'}>{data?.collagen?.home_collagen_impression_text}</div>
                <p className={'text-[14px] lg:text-[16px] xxl:text-[20px] text-[#F7F4ED] italic font-[300] leading-[28px] mb-[28px]'}>{data?.collagen?.home_collagen_impression_izvor}</p>
                <div className={'border-b border-[#F7F4ED] w-[150px] md:w-[600px] mx-auto items-center mb-[23px] md:mb-[34px]'}></div>
                <button onClick={() => router.push('/utisci?filter=collagen')} className={'bg-white text-[#626261] rounded-[30px] px-[30px] md:px-[70px] py-[10px] md:py-[12px] text-[12px] md:text-[16px] font-[800] tracking-[1.2px] md:tracking-[1.6px] mb-[23px] md:mb-[0px]'}>{data?.collagen?.collagen_study_impressions_button_text}</button>
            </div>
        </MainContainerWrapper>
        <div className={'flex justify-center items-center mx-auto mt-[80px] pb-[46px]'}>
            {data?.collagen?.collagen_links_repeater.map((item, index, array) => (
                <div key={index}
                     onClick={() => handleImageClick(item?.lunk_route)}
                     className={`flex flex-col md:flex-row justify-center items-center h-[60px] md:gap-[20px] md:h-auto ${index < array.length - 1 ? ' pr-[10px] md:pr-5 mr-[10px] md:mr-5 border-r' : ''} border-cruxGreen`}>
                    <img width={isMobile ? '30px' :'65px'} height={isMobile ? '28px' :'60px'} src={item?.link_image} alt="Collagen" className={'mb-[10px] h-[60px] object-contain'}/>
                    <p className="text-[12px] lg:text-[16px] xxl:text-[20px] font-[400] md:font-[300] leading-[13px] md:leading-[28px] max-w-[140px] md:max-w-fit text-center">{item?.link_text.toUpperCase()}</p>
                </div>
            ))}
        </div>
    </div>)

}
export default HomeCollagenImpression