'use client'
import React from "react";
import useWindowSize from "@/app/hooks/useWindowSize";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";


const HomeCollagenWhatIsIn = ({data}) => {

    const {isMobile} = useWindowSize()

    const whatIsInImage = {
        backgroundImage: `url('${data.collagen?.collagen_what_is_in_bg_image}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        width: '100%',
    };
    const whatIsInImageMobile = {
        backgroundImage: `url('${data.collagen?.collagen_what_is_in_bg_image_mobile}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width: '100%',
        height: '586px'
    };

    return (<div className={'mb-[72px] md:mb-[0px]'}>
        <div className={'hidden md:block xl:h-[900px] 2xl:h-[1000px] 3xl:h-[1100px]'} style={whatIsInImage}>
            <div className={'flex flex-col w-full mx-auto items-center text-[#626261]'}>
                <div className={' text-[14px] leading-[24px] tracking-[8.4px] font-[300] mb-[29px]'}>{data?.collagen?.collagen_what_is_in_title}</div>
                <h1 className={' text-[48px] font-black leading-[40px] tracking-[4.8px]'}>{data?.collagen?.collagen_what_is_in_subtitle1}</h1>
                <h2 className={' text-[32px] font-[300] leading-[40px] tracking-[7.2px] mb-[13px]'}>{data?.collagen?.collagen_what_is_in_subtitle2}</h2>
                <div className={'border-b border-[#626261] w-[600px] mx-auto mb-[45px]'}></div>
            </div>
            <div className={'bg-cruxGreen relative right-[-45%] rounded-[30px] px-[50px] py-[20px] xxl:py-[50px] text-white lg:w-1/2 xxl:w-1/2 2xl:w-1/2 3xl:w-1/2 max-w-[974px]'}>
                {data?.collagen?.collagen_what_is_in_repeater.map((item, index) => (
                    <div className={'w-fit xxl:w-[550px]'} key={index}>
                        <div className={'text-[24px] lg:text-[18px] xxl:text-[24px] font-[700] leading-[30px] tracking-[1.2px] ml-auto'} dangerouslySetInnerHTML={{ __html: item.collagen_what_is_in_repeater_title.toUpperCase()}}/>
                        <div className={'border-b border-white w-[300px]'}></div>
                        <p className={'lg:text-[14px] xxl:text-[20px] font-[300] leading-[28px] text-justify my-[12px] mb-[20px] xxl:mb-[65px]'}>{item.collagen_what_is_in_text}</p>
                    </div>
                ))}
            </div>
        </div>
        <div className={'block md:hidden'}>
            <div className={'flex flex-col w-full mx-auto items-center text-cruxGray'}>
                <div className={'text-[10px] leading-[24px] tracking-[6px] font-[300] mb-[14px]'}>{data?.collagen?.collagen_what_is_in_title}</div>
                <h1 className={'text-[24px] font-black leading-[24px] tracking-[2.4px]'}>{data?.collagen?.collagen_what_is_in_subtitle1}</h1>
                <h2 className={'text-[20px] font-[300] leading-[24px] tracking-[4px] mb-[12px]'}>{data?.collagen?.collagen_what_is_in_subtitle2}</h2>
                <div className={'border-b border-[#626261] w-[150px] mx-auto mb-[21px]'}></div>
            </div>
            <div className={'w-full'} style={whatIsInImageMobile}>
                <img width={'60%'} className={'mx-auto pt-[30px]'} src={data?.collagen?.home_collagen_benefits_image}/>
            </div>
            <MainContainerWrapper>
                <div className={'bg-cruxGreen rounded-[30px] text-white pt-[46px] pb-[5px] mt-[-150px]'}>
                    {data?.collagen?.collagen_what_is_in_repeater.map((item, index) => (
                        <div className={'w-full mx-auto items-center text-center'} key={index}>
                            <div className={'px-[14px] text-[22px] text-center font-[700] leading-[30px] tracking-[1.2px] mb-[12px]'} dangerouslySetInnerHTML={{ __html: item.collagen_what_is_in_repeater_title.toUpperCase()}}/>
                            <div className={'border-b border-white mx-auto w-[150px]'}></div>
                            <p className={'px-[30px] text-[20p] font-[300] leading-[28px] text-center my-[12px] mb-[42px]'}>{item.collagen_what_is_in_text}</p>
                        </div>
                    ))}
                </div>
            </MainContainerWrapper>
        </div>
    </div>)

}
export default HomeCollagenWhatIsIn