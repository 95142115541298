'use client'
import React from "react";

import "@/app/styles/global.scss";
import { Inter } from "next/font/google";
import {useRouter} from "next/navigation";
const inter = Inter({ subsets: ["latin"] });

const HomeCollagenWakeUpBeige = ({data}) => {

    const router = useRouter();

    return(<div className={'w-full mb-[100px] md:mb-[0px] md:pt-[200px] md:pb-[150px] md:bg-cruxBeige'}>
        <div className={'flex flex-col w-full md:flex-row gap-[40px] md:gap-[0px]'}>
            <div className={'text-center'}>
                <div className={'block md:hidden text-[10px] text-cruxGray font-[300] leading-[24px] tracking-[6px] mb-[14px]'}>{data?.collagen?.collagen_wake_up_title}</div>
                <h1 className={'block md:hidden text-cruxGray text-[24px] font-black leading-[24px] tracking-[2.4px]'}>{data?.collagen?.collagen_wake_up_subititle1}</h1>
                <div className={'block md:hidden text-cruxGray text-[20px] font-[300] leading-[24px] tracking-[4px] mb-[20px]'}>{data?.collagen?.collagen_wake_up_subtitle2}</div>
                <div className={'block md:hidden text-cruxGray border-b border-cruxGray w-[150px] mx-auto'}></div>
            </div>
            <div className={'block md:hidden w-full'}>
                <div className={'bg-goldGradient2 rounded-r-[30px] h-[251px] w-1/2 flex justify-center items-center'}>
                    <img className={'absolute w-[90%] right-[10px] pt-[40px]'} src={data?.collagen?.collagen_wake_up_image} alt="Collagen Wake Up"/>
                </div>
            </div>
            <div className={'flex flex-row justify-between w-full'}>
                <div className={'hidden md:flex w-[40%] '}>
                    <div className={'bg-goldGradient2 rounded-r-[30px] max-h-[320px] w-full flex justify-center items-center'}>
                        <img className={'max-w-full h-auto relative right-[-100px]'} src={data?.collagen?.collagen_wake_up_image} alt="Collagen Wake Up"/>
                    </div>
                </div>
                <div className={'flex flex-col justify-between md:w-1/2 mx-auto md:mx-[0px]'}>
                    <div className={'hidden md:block text-[14px] text-[#626261] font-[300] leading-[24px] tracking-[8.4px]'}>{data?.collagen?.collagen_wake_up_title}</div>
                    <h1 className={'hidden md:block text-cruxGreen text-[40px] xxl:text-[48px] font-black leading-[40px] tracking-[4.8px]'}>{data?.collagen?.collagen_wake_up_subititle1}</h1>
                    <div className={'hidden md:block text-cruxGray text-[30px] xxl:text-[36px] font-[300] leading-[40px] tracking-[7.2px]'}>{data?.collagen?.collagen_wake_up_subtitle2}</div>
                    <div className={'border-b border-cruxBrown w-[150px] mx-auto md:mx-[0px] lg:w-[500px] xxl:w-[550px] mt-[40px]'}></div>
                    {/*<div className={'text-cruxGray cursor-pointer underline text-[16px] md:text-[20px] leading-[20px] md:leading-[33px] font-[300] max-w-[207px] md:max-w-full mx-auto md:mx-[0px] text-center md:text-start md:font-[400] mt-[9px] mb-[14px]'}>*/}
                    {/*    <div onClick={() => router.push('/refund_returns')} className={inter.className} dangerouslySetInnerHTML={{ __html: data?.collagen?.collagen_wake_up_text}}></div>*/}
                    {/*</div>*/}
                    <button onClick={() => router.push('/prodavnica')} className={'cursor-pointer bg-cruxGreen mx-auto md:mx-0 w-fit rounded-[30px] text-[#F7F4ED] px-[30px] py-[10px] md:px-[70px] md:py-[16px] font-[800] tracking-[1.6px] mt-[14px]'}>{data?.collagen?.collagen_wake_up_button_text}</button>
                </div>
            </div>
        </div>
    </div>)
}
export default HomeCollagenWakeUpBeige