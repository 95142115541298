'use client'
import {useRouter} from "next/navigation";

const HomeCollagenWin = ({data}) => {

    const router = useRouter();

    const imageStyle = {
        backgroundImage: `url('${data.collagen?.home_collagen_win_bg_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width:"100%",
        // height: "1200px",
    }

    const imageStyleMobile = {
        backgroundImage: `url('${data.collagen?.home_collagen_win_bg_image_mobile}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width:"100%",
        height: "500px",
    }

    return<div className={'md:bg-cruxBeige'}>
        <div className='hidden lg:block w-full items-center mb-[-420px] 3xl:mb-[-400px] h-[1200px] 3xl:h-[1500px]' style={imageStyle}>
            <div className='w-1/2 ml-[100px] xl:ml-[280px] 2xl:ml-[370px] 3xl:ml-[480px] flex flex-col gap-2 items-start'>
                {/*<div className='text-[#626261] text-[14px] font-[300] leading-[24px] tracking-[8.4px] mt-[150px]'>{data.collagen?.home_win_collagen_title}</div>*/}
                <h1 className='text-cruxGray text-[40px] xxl:text-[48px] font-black leading-[40px] tracking-[4.8px] mt-[170px] 3xl:mt-[200px]'>{data.collagen?.home_win_collagen_subtitle1}</h1>
                <h2 className='mb-1 lg:text-[24px] xxl:text-[32px] font-[300] leading-[40px] tracking-[6.4px] text-cruxGreen md:pb-[14px]'>{data.collagen?.home_win_collagen_subtitle2}</h2>
                <div className={'border-b border-cruxBrown lg:w-[500px] xxl:w-[600px]'}></div>
                <p className='text-[#626261] text-[16px] xxl:text-[20px] font-[300] leading-[28px] md:mt-[17px]'>{data.collagen?.home_win_collagen_text}</p>
                <button onClick={() => router.push('/video-review')} className='bg-cruxGreen rounded-[30px] text-cruxBeige md:mt-[25px] px-[40px] md:px-[70px] py-[10px] md:py-[16px] font-[800] tracking-[1.6px]'>{data.collagen?.home_win_collagen_button_text}</button>
            </div>
        </div>
        <div className={'block lg:hidden items'}>
            <div className={'flex flex-col items-center'}>
                <p className='text-cruxGray font-[300] text-[10px] tracking-[6px] mb-[14px] leading-[24px]'>{data.collagen?.home_win_collagen_title}</p>
                <h1 className='text-cruxGray font-[900] leading-[31px] tracking-[2.4px] text-[24px]'>{data.collagen?.home_win_collagen_subtitle1}</h1>
                <h2 className='mb-1 text-[24px] text-cruxGray font-[300] tracking-[5px] text-center'>{data.collagen?.home_win_collagen_subtitle2}</h2>
                <span className={'border-b border-cruxGray w-[150px] items-center'}></span>
                <p className='text-[#626261] leading-[24px] max-w-[210px] text-cruxGray text-center'>{data.collagen?.home_win_collagen_text}</p>
                <button onClick={() => router.push('/video-review')} className={'bg-cruxGreen text-white font-[800] tracking-[1.2px] rounded-[30px] px-[70px] md:px-[20px] py-[12px] md:py-[10px] mt-[20px] w-fit mx-auto'}>{data.collagen?.home_win_collagen_button_text}</button>
            </div>
            <div style={imageStyleMobile}></div>
        </div>
    </div>
}
export default HomeCollagenWin