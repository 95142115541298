import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenAcceptChallenge.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenBanner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenClinicalStudy.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenImpression.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenTestimonials.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenWakeUp.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenWakeUpBeige.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenWhatIsIn.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/pages/collagen/HomeCollagenWin.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/not-found.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/components/pages/home/home-day-medicine.module.scss");
