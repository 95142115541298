'use client'
import ContainerWrapper from "@/app/components/common/ContainerWrapper";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";
import useWindowSize from "@/app/hooks/useWindowSize";
import {useRouter} from "next/navigation";

const HomeCollagenClinicalStudy = ({data}) => {

    const {isMobile} = useWindowSize()
    const router = useRouter();

    const clinicalStudyImage = {
        backgroundImage: `url('${data.collagen?.collagen_study_bg_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        width: '100%',
        height: '1900px',
    };

    const clinicalStudyImageMobile = {
        backgroundImage: `url('${data.collagen?.collagen_study_bg_image_mobile}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        width: '100%',
        height: '1750px',
    };

    return (<div className={'bg-white mb-[220px] md:mb-[0px]'} style={isMobile ? clinicalStudyImageMobile : clinicalStudyImage}>
        <ContainerWrapper>
            <div className={'w-full md:bg-[#F7F4ED]  bg-gradient-to-b from-[#F7F4ED] to-transparent z-10 px-[5px] text-center md:px-[120px] pt-[130px] rounded-[30px] max-w-[1200px] mx-auto mt-[-100px]'}>
                <div className={'flex flex-col justify-center items-center mx-auto text-[#626261]'}>
                    <h1 className={'text-[#626261] text-[24px] tracking-[2.4px] leading-[24px] md:text-[32px] font-black md:leading-[32px] md:tracking-[3.2px]'}>{data?.collagen?.collagen_study_title}</h1>
                    <h2 className={'text-[#626261] text-[14px] leading-[22px] tracking-[4.2px] md:text-[24px] font-[400] md:leading-[32px] md:tracking-[8.4px] mb-[12px] md:mb-[57px]'}>{data?.collagen?.collagen_study_subtitle}</h2>
                    <div className={'block md:hidden border-b border-cruxBrown w-[150px] mx-auto mb-[36px] md:mb-[57px]'}></div>
                    <div className="video-container relative overflow-hidden md:rounded-[30px] mb-[70px] w-full" style={{ paddingTop: "56.25%" }}>
                        <iframe
                            className="absolute top-0 left-0 w-full h-full"
                            src="https://www.youtube.com/embed/We1-HztVmWU?rel=0&autoplay=1&mute=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    {/*<div className="video-container overflow-hidden w-full md:rounded-[30px] mb-[70px]">*/}
                    {/*    <iframe*/}
                    {/*        className="w-full"*/}
                    {/*        // width={isMobile ? "350px" : "970px"}*/}
                    {/*        height={isMobile ? "220px" : "540px"}*/}
                    {/*        src={'https://www.youtube.com/embed/We1-HztVmWU?rel=0&autoplay=1&mute=1'}*/}
                    {/*        title="YouTube video player"*/}
                    {/*        frameBorder="0"*/}
                    {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                    {/*        allowFullScreen*/}
                    {/*    ></iframe>*/}
                    {/*</div>*/}
                    <div>
                        {data?.collagen?.collagen_study_repeater.map((item, index) => (
                            <div className={'flex flex-col items-center text-center max-w-[600px]'} key={index}>
                                <h2 className={'text-cruxGreen text-[20px] leading-[30px] tracking-[1px] lg:text-[22px] xxl:text-[24px] font-[700] md:leading-[30px] md:tracking-[1.2px] mb-[12px]'}>{item.collagen_study_repeater_title}</h2>
                                <div className={'border-b border-cruxBrown w-[150px] md:w-[300px] mx-auto mb-[12px]'}></div>
                                <p className={'text-[16px] xxl:text-[20px] font-[400] w-[300px] lg:w-[500px] xxl:w-fit md:font-[300] leading-[24px] md:leading-[28px] mb-[50px] md:mb-[114px]'}>{item.collagen_study_repeater_text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ContainerWrapper>
        <div className={'md:mx-[-28px]'}>
        <MainContainerWrapper>
            <div className={'md:mt-[-290px] bg-cruxGreen z-[5] w-full  rounded-[30px] pt-[20px] md:pt-[255px] pb-[30px] md:pb-[0px]'}>
                <div className={'flex w-full flex-col text-white items-center text-center'}>
                    <div className={'border-b border-white w-[150px] md:w-[600px] mx-auto mb-[42px] pt-[93px]'}></div>
                    <div className={'px-[25px] md:px-0 text-[16px] lg:text-[18px] xxl:text-[20px] font-[400] leading-[20px] md:leading-[24px] tracking-[1.6px] md:tracking-[2px] mb-[23px] w-full max-w-[600px]'}>{data?.collagen?.collagen_study_impression_text}</div>
                    <p className={'text-[14px] lg:text-[16px] xxl:text-[20px] font-[300] italic leading-[28px]'}>{data?.collagen?.collagen_study_izvor}</p>
                    <div className={'border-b border-white w-[150px] md:w-[600px] mx-auto mt-[28px] mb-[23px] md:mb-[34px]'}></div>
                    <button onClick={ () => router.push('/utisci?filter=collagen')} className={'bg-white text-[#626261] rounded-[30px] px-[30px] md:px-[70px] py-[10px] md:py-[12px] text-[16px] md:text-[16px] font-[800] tracking-[1.2px] md:tracking-[1.6px] mb-[63px] md:mb-[33px]'}>{data?.collagen?.collagen_study_impressions_button_text}</button>
                </div>
            </div>
        </MainContainerWrapper>
        </div>
    </div>)
}
export default HomeCollagenClinicalStudy