'use client'
import {useRouter} from "next/navigation";
import useWindowSize from "@/app/hooks/useWindowSize";

const HomeCollagenBanner = ({data}) => {

    const router = useRouter();
    const {isMobile} = useWindowSize()

    const bannerImage = {
        backgroundImage: `url('${data.collagen?.collagen_banner_background_image}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        width: '100%',
        height: '100vh',
    };

    const bannerImageMobile = {
        backgroundImage: `url('${data.collagen?.collagen_banner_background_image_mobile}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        width: '100%',
        height: '750px',
    };

    return (<div className={'bg-cruxBeige lg:mt-[-65px]'} style={isMobile ? bannerImageMobile : bannerImage}>
        <div className={'hidden md:flex flex-row justify-center items-center lg:pt-[0px] xxl:pt-[0px] 4xl:pt-[200px]'}>
            <div className={'flex flex-col text-center items-center lg:ml-[100px] xxl:ml-[250px]'}>
                <img className={'mb-[12px] lg:w-[300px] xl:w-[350px] xxl:w-[500px]'}  src={data?.collagen?.collagen_crux_logo_image}/>
                <h1 className={'text-cruxGreen lg:text-[16px] xl:text-[20px] xxl:text-[24px] font-[300] lg:leading-[30px] xxl:leading-[51.5px] lg:tracking-[8.4px] xxl:tracking-[12px] mb-[20px]'}>{data?.collagen?.collagen_banner_title}</h1>
                <h2 className={'text-cruxGreen text-[16px] xxl:text-[20px] font-[700] leading-[24px] tracking-[6px] mb-[8px]'}>{data?.collagen?.collagen_banner_subtitle1}</h2>
                <div className={'text-[#8F7E76] text-[16px] font-[400] leading-[24px] tracking-[3.6px] xxl:tracking-[4.8px] mb-[30px]'}>{data?.collagen?.collagen_banner_subtitle2}</div>
                <button onClick={() => router.push('/prodavnica')} className={'bg-cruxGreen text-white text-[16px] font-[800] tracking-[1.6px] max-w-[290px] rounded-[30px] px-[60px] py-[16px]'}>{data?.collagen?.collagen_banner_button_text}</button>
            </div>
            <div>
                <img className={'lg:w-[450px] xl:w-[500px] xxl:w-[700px] 2xl:w-[750px] 3xl:w-[822px]'} src={data?.collagen?.collagen_box_image}/>
            </div>
        </div>
        <div className={'flex-col md:hidden flex-col justify-center items-center pt-[75px]'}>
            <div className={'flex flex-col text-center items-center'}>
                <img className={'mb-[15px]'} width={220} height={40} src={data?.collagen?.collagen_crux_logo_image}/>
                <h1 className={'text-[#8F7E76] text-[14px] font-[700] leading-[16px] tracking-[4.2px] mb-[40px]'}>{data?.collagen?.collagen_banner_title}</h1>
                <img width={400} src={data?.collagen?.collagen_box_image}/>
                {/*<h2 className={'text-cruxGreen text-[20px] font-[700] leading-[24px] tracking-[6px] mb-[8px]'}>{data?.collagen?.collagen_banner_subtitle1}</h2>*/}
                {/*<div className={'text-[#8F7E76] text-[16px] font-[400] leading-[24px] tracking-[4.8px] mb-[30px]'}>{data?.collagen?.collagen_banner_subtitle2}</div>*/}
                <button onClick={() => router.push('/prodavnica')} className={'bg-cruxGreen text-white text-[16px] font-[800] tracking-[1.6px] max-w-[290px] rounded-[30px] px-[30px] md:px-[70px] py-[12px] md:py-[16px]'}>{data?.collagen?.collagen_banner_button_text}</button>
            </div>
            <div>
            </div>
        </div>
    </div>)

}
export default HomeCollagenBanner