'use client'
import {useRouter} from "next/navigation";
import MainContainerWrapper from "@/app/components/common/MainContainerWrapper";

const HomeCollagenAcceptChallenge = ({data}) => {

    const router = useRouter();

    return(<div className='md:bg-cruxBeige'>
        <MainContainerWrapper>
            <div className='hidden md:block bg-cruxGreen rounded-[30px] w-full h-[350px] py-[50px] 2xl:mt-[70px] text-center text-white'>
                <div className='text-[42px] xxl:text-[48px] tracking-[4.8px] leading-[40px] font-[900] mt-[10px]'>{data?.collagen?.home_collagen_collect_title}</div>
                <div className='text-[28px] xxl:text-[32px] tracking-[6.4px] leading-[40px] font-[300] mb-[30px]'>{data.collagen?.home_collagen_collect_subtitle}</div>
                <p className='max-w-[680px] text-[18px] xxl:text-[20px] font-[300] leading-[28px] mx-auto mb-[20px]'>{data.collagen?.home_collagen_collect_text}</p>
                {/*<button onClick={() => router.push('/accept-challenge')} className='bg-white rounded-[30px] px-[50px] py-[12px] text-cruxGreen tracking-[1.6px] font-[800]'>{data.collagen?.home_collagen_collect_button}</button>*/}
                <a href="/accept-challenge" className="bg-white rounded-[30px] px-[50px] py-[12px] tracking-[1.6px] text-cruxGreen font-[900]">
                    {data?.collagen?.home_collagen_collect_button}
                </a>
            </div>
            <div className='block md:hidden bg-cruxGreen rounded-[30px] w-full h-[350px] py-[50px] mb-[100px] text-center text-white'>
                <div className='text-[20px] tracking-[1px] leading-[22px] font-[800] mt-[10px]'>{data?.collagen?.home_collagen_collect_title}</div>
                <div className='text-[20px] tracking-[1px] leading-[22px] font-[400] mb-[30px]'>{data.collagen?.home_collagen_collect_subtitle}</div>
                <p className='max-w-[680px] font-[300] mx-auto mb-[20px]'>{data.collagen?.home_collagen_collect_text}</p>
                {/*<button onClick={() => router.push('/accept-challenge')} className='bg-white rounded-[30px] px-[50px] py-[12px] text-cruxGreen font-[800]'>{data.collagen?.home_collagen_collect_button}</button>*/}
                <a href="/accept-challenge" className="bg-white rounded-[30px] px-[50px] py-[12px] text-cruxGreen font-[800]">
                    {data.collagen?.home_collagen_collect_button}
                </a>
            </div>
        </MainContainerWrapper>
    </div>)
}
export default HomeCollagenAcceptChallenge